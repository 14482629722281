import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "3f23ecc8-0e10-4059-935b-caa14d02e8d4"
// title: "Members Area Header"
// type: :html
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 header-wrapper`} widgetId="c0204c14-06ef-4cf2-8e16-8b9bbc1ba21e">
      <div className="background-image" />
      {props["header-top"] || <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopF0a7547bA0dd4f69Af26Bab606ccf6f2" navMenuSlug="members-primary-nav" {...props} />}
    </div>
  );
}

// id: "eab9b49d-cf24-45ed-9fc1-0a123955add4"
// title: ""
// type: :text
// key: "navigationPurpose"
// parent_id: "51825e22-4cf8-4343-8afd-166ba0ffe905"
export const NavigationPurposeEab9b49dCf2445ed9fc10a123955add4 = "primary";

// id: "70dabe96-c112-4895-b328-3011e38c4168"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ae61bb66-76ff-4bc0-b32c-c5616fc39386"
export function Header70dabe96C1124895B3283011e38c4168(props: any) {
  return (
    <HomeHeader6ccf55d960d849f29ba2F1da3bfae29d parentTag="Header70dabe96C1124895B3283011e38c4168" {...props} />
  );
}

// id: "d898715e-cf33-4c12-9333-a0a35b847533"
// title: "Home Body"
// type: :html
// key: "body"
// parent_id: "ae61bb66-76ff-4bc0-b32c-c5616fc39386"
export function BodyD898715eCf334c129333A0a35b847533(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} BodyD898715eCf334c129333A0a35b847533`}>
      {props["home-marketing-cards"] || <MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef parentTag="HomeMarketingCards7eca47775fbf4e6f9a75E6a40ac9ab52" postSlug="home-page-posts2" {...props} />}
    </main>
  );
}

// id: "7eca4777-5fbf-4e6f-9a75-e6a40ac9ab52"
// title: "Marketing Cards Reference"
// type: :reference
// key: "home-marketing-cards"
// parent_id: "d898715e-cf33-4c12-9333-a0a35b847533"
export function HomeMarketingCards7eca47775fbf4e6f9a75E6a40ac9ab52(props: any) {
  return (
    <MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef parentTag="HomeMarketingCards7eca47775fbf4e6f9a75E6a40ac9ab52" postSlug="home-page-posts2" {...props} />
  );
}

// id: "8a31c08b-35f4-4c5c-8829-fd50642deafd"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards8a31c08b35f44c5c8829Fd50642deafd(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards8a31c08b35f44c5c8829Fd50642deafd", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "f760c2dd-8d5a-43bf-93b3-2cafb682fb7d"
// title: "Home Footer"
// type: :reference
// key: "footer"
// parent_id: "ae61bb66-76ff-4bc0-b32c-c5616fc39386"
export function FooterF760c2dd8d5a43bf93b32cafb682fb7d(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterF760c2dd8d5a43bf93b32cafb682fb7d" {...props} />
  );
}

// id: "16fa6140-9f3c-4fef-951c-ab8d597b790f"
// title: "Welcome Back Pop Up"
// type: :gf_preset
// key: "welcome-back"
// parent_id: "ae61bb66-76ff-4bc0-b32c-c5616fc39386"
export function WelcomeBack16fa61409f3c4fef951cAb8d597b790f(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack16fa61409f3c4fef951cAb8d597b790f", parentTag)} {...props} />
  );
}

// id: "51825e22-4cf8-4343-8afd-166ba0ffe905"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop51825e224cf843438afd166ba0ffe905(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop51825e224cf843438afd166ba0ffe905 header-top`} navigationPurpose="primary" widgetId="7913b988-d1ef-4a49-a427-f244e18777c5">
      <div className="header-top-opacity" />
      <div className="header-inner">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo298ceebc71d84dd6A3ec246dd30daa1b", parentTag)} label={<DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label1db8d867713945ceB75a7a79cbc19d5f" alt="logo" />} className="linked-logo" url="/" {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation7294f303066f4205Be97C225a0612464", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "87adb067-64d7-4936-8436-e220dc9446f3"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "75cd850e-75c0-40ee-a6f8-0490d57437f8"
export const PostSlug87adb06764d749368436E220dc9446f3 = "about-us-posts";

// id: "a7f50569-b52f-4b24-b404-48438cda031c"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "557999cb-a0d0-4b1e-b548-8ef11303e699"
export const ContentSlugA7f50569B52f4b24B40448438cda031c = "home-hero-title";

// id: "03da9055-0482-4f25-8b4b-71074a8a3226"
// title: ""
// type: :text
// key: "className"
// parent_id: "557999cb-a0d0-4b1e-b548-8ef11303e699"
export const ClassName03da905504824f258b4b71074a8a3226 = "title";

// id: "b6831adc-63ec-4c13-a3ff-678eb08477ed"
// title: "Home Page Post Slug"
// type: :text
// key: "postSlug"
// parent_id: "7eca4777-5fbf-4e6f-9a75-e6a40ac9ab52"
export const PostSlugB6831adc63ec4c13A3ff678eb08477ed = "home-page-posts2";

// id: "75cd850e-75c0-40ee-a6f8-0490d57437f8"
// title: "About - Marketing Cards"
// type: :reference
// key: "about-marketing-cards"
// parent_id: "7df8a016-309e-493e-86ff-3656f24406d5"
export function AboutMarketingCards75cd850e75c040eeA6f80490d57437f8(props: any) {
  return (
    <MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef parentTag="AboutMarketingCards75cd850e75c040eeA6f80490d57437f8" postSlug="about-us-posts" {...props} />
  );
}

// id: "9edd68b8-8dca-4252-828b-48444491155d"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "51825e22-4cf8-4343-8afd-166ba0ffe905"
export const WidgetId9edd68b88dca4252828b48444491155d = "7913b988-d1ef-4a49-a427-f244e18777c5";

// id: "557999cb-a0d0-4b1e-b548-8ef11303e699"
// title: ""
// type: :gf_preset
// key: "title"
// parent_id: "7c64c36e-d948-41f9-9c13-55c14a5b553f"
export function Title557999cbA0d04b1eB5488ef11303e699(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("Title557999cbA0d04b1eB5488ef11303e699", parentTag)} contentSlug="home-hero-title" className="title" {...props} />
  );
}

// id: "f6fd381f-bc2f-4529-a256-4199aa23d3a1"
// title: ""
// type: :gf_preset
// key: "body"
// parent_id: "7c64c36e-d948-41f9-9c13-55c14a5b553f"
export function BodyF6fd381fBc2f4529A2564199aa23d3a1(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("BodyF6fd381fBc2f4529A2564199aa23d3a1", parentTag)} className="body" contentSlug="home-hero-body" {...props} />
  );
}

// id: "77d7a9c1-51c6-4c85-bc96-2f83e19a8532"
// title: ""
// type: :text
// key: "className"
// parent_id: "f6fd381f-bc2f-4529-a256-4199aa23d3a1"
export const ClassName77d7a9c151c64c85Bc962f83e19a8532 = "body";

// id: "bd44a166-41db-41f1-9207-6e5d0349872a"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "f6fd381f-bc2f-4529-a256-4199aa23d3a1"
export const ContentSlugBd44a16641db41f192076e5d0349872a = "home-hero-body";

// id: "9382b9d9-2141-48ce-bd53-f6738ef114ec"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "7c64c36e-d948-41f9-9c13-55c14a5b553f"
export function Button9382b9d9214148ceBd53F6738ef114ec(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("Button9382b9d9214148ceBd53F6738ef114ec", parentTag)} contentSlug="home-hero-button" className="button-wrapper" {...props} />
  );
}

// id: "e12a7f1f-be35-4ff7-83b6-401fa003f5e9"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "9382b9d9-2141-48ce-bd53-f6738ef114ec"
export const ContentSlugE12a7f1fBe354ff783b6401fa003f5e9 = "home-hero-button";

// id: "c6743492-e9a0-4a12-919f-e12d5d4885ba"
// title: ""
// type: :text
// key: "className"
// parent_id: "9382b9d9-2141-48ce-bd53-f6738ef114ec"
export const ClassNameC6743492E9a04a12919fE12d5d4885ba = "button-wrapper";

// id: "7c64c36e-d948-41f9-9c13-55c14a5b553f"
// title: ""
// type: :html
// key: "hero"
// parent_id: "6ccf55d9-60d8-49f2-9ba2-f1da3bfae29d"
export function Hero7c64c36eD94841f99c1355c14a5b553f(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} Hero7c64c36eD94841f99c1355c14a5b553f`}>
      <div className="opacity" />
      <div className="hero-main-container">
        <div className="hero-inner-content-container">
          <div className="hero-content-container">
            {props["title"] || <ContentSnippet parentTag={buildClassName("Title557999cbA0d04b1eB5488ef11303e699", parentTag)} contentSlug="home-hero-title" className="title" {...props} />}
            {props["body"] || <ContentSnippet parentTag={buildClassName("BodyF6fd381fBc2f4529A2564199aa23d3a1", parentTag)} className="body" contentSlug="home-hero-body" {...props} />}
            {props["button"] || <ContentSnippet parentTag={buildClassName("Button9382b9d9214148ceBd53F6738ef114ec", parentTag)} contentSlug="home-hero-button" className="button-wrapper" {...props} />}
          </div>
          <div className="hero-image-container">
            <div className="hero-image--blue-container" />
            <div className="hero-image-inner-container">
              <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/CgqKXjrIIDYiOKS0_regular.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "bbc37523-6cfb-45a4-b6f5-09d4d39c2293"
// title: "Donate Button"
// type: :gf_preset
// key: "donate-button"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function DonateButtonBbc375236cfb45a4B6f509d4d39c2293(props: any) {
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <ContentSnippet parentTag={buildClassName("DonateButtonBbc375236cfb45a4B6f509d4d39c2293", parentTag)} contentSlug="footer-donate-button" className="donate-button" {...props} />
  );
}

// id: "2d307369-38e4-41b0-9518-53950e96e678"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "bbc37523-6cfb-45a4-b6f5-09d4d39c2293"
export const ContentSlug2d30736938e441b0951853950e96e678 = "footer-donate-button";

// id: "c6b3d02b-7866-4438-bd42-f609593e225c"
// title: ""
// type: :text
// key: "className"
// parent_id: "bbc37523-6cfb-45a4-b6f5-09d4d39c2293"
export const ClassNameC6b3d02b78664438Bd42F609593e225c = "donate-button";

// id: "941ebc68-97e0-49a5-893a-7043db151e4a"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages941ebc6897e049a5893a7043db151e4a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages941ebc6897e049a5893a7043db151e4a gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "63d85fb5-e62c-4cd4-b0a3-f641aa47bda1"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label63d85fb5E62c4cd4B0a3F641aa47bda1(props: any) {
  return (
    <DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label63d85fb5E62c4cd4B0a3F641aa47bda1" {...props} />
  );
}

// id: "920afe7d-c23e-4d44-9444-6d3331a5f4bb"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault920afe7dC23e4d4494446d3331a5f4bb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault920afe7dC23e4d4494446d3331a5f4bb page`}>
      {props["header"] || <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="HeaderCd7dda6c3cf84301B87289c996f2ea14" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterE5a4aeaaE4094bd0BdfeAb7c52c1b57a" {...props} />}
    </div>
  );
}

// id: "4f1cd43d-d1fb-4a10-959b-1dd9bf449394"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage4f1cd43dD1fb4a10959b1dd9bf449394(props: any) {
  return (
    <SecondaryPageC3e07e23035245d58e1915c42dd2261e parentTag="ResourcesPage4f1cd43dD1fb4a10959b1dd9bf449394" header={<SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderAe8349783e4c4a88Ac5493080d89caa3" title="Resources" />} {...props} />
  );
}

// id: "b14b2c2c-4872-4144-935f-27c99f672857"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderB14b2c2c48724144935f27c99f672857(props: any) {
  return (
    <ShortHeader55b21936607f4a9e81767e09718c8760 parentTag="DefaultHeaderB14b2c2c48724144935f27c99f672857" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "c3e07e23-0352-45d5-8e19-15c42dd2261e"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageC3e07e23035245d58e1915c42dd2261e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageC3e07e23035245d58e1915c42dd2261e gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header63da9b4eBe0b4e6a965c8e3cd43da58a" title="Secondary Page" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer82e0f4168bd946c88164A9d68cf5e2d2" {...props} />}
    </div>
  );
}

// id: "2689e5b7-af3e-48b5-ae56-8f47897c6077"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlug2689e5b7Af3e48b5Ae568f47897c6077 = "about-us-posts";

// id: "9e5ae215-c543-4912-b6a9-f13f832e8a52"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage9e5ae215C5434912B6a9F13f832e8a52(props: any) {
  return (
    <SecondaryPageC3e07e23035245d58e1915c42dd2261e parentTag="JoinUsPage9e5ae215C5434912B6a9F13f832e8a52" header={<SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderB0e261a0E5ce4fc6B8886aab1a5314c2" title="Join Us" />} {...props} />
  );
}

// id: "97c6b8a4-28c5-4ace-847c-106847e4f4db"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href97c6b8a428c54ace847c106847e4f4db = "/";

// id: "0722eb7a-1f7d-49bf-8fa8-c065ba9f2a9e"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage0722eb7a1f7d49bf8fa8C065ba9f2a9e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header7db43ffe806d4c5cB2917031b998e19e" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterBe25145e432544c9A817Ce7f9cd8bf77" {...props} />}
    </>
  );
}

// id: "e226638c-a067-48b6-9718-67905336a3a6"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyE226638cA06748b6971867905336a3a6(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyE226638cA06748b6971867905336a3a6 page-body`}>
      {props["marketing-cards2"] || <MarketingCards2D57bd2f76a0843cb872f1c4155897f22 />}
    </div>
  );
}

// id: "647c7dff-7447-4c34-9620-876fd9363ec4"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote647c7dff74474c349620876fd9363ec4(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote647c7dff74474c349620876fd9363ec4`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "1e0365f7-31be-48b9-a7af-d8cd1dd2217d"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer1e0365f731be48b9A7afD8cd1dd2217d(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer1e0365f731be48b9A7afD8cd1dd2217d" {...props} />
  );
}

// id: "e200c486-0562-43ae-b481-70c4408d2874"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesE200c486056243aeB48170c4408d2874(props: any) {
  return (
    <SecondaryPageC3e07e23035245d58e1915c42dd2261e parentTag="SignupPagesE200c486056243aeB48170c4408d2874" header={<SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header4eb4a2f3E8704abbAa6e1acde6c6e1a3" title="Join Us" />} {...props} />
  );
}

// id: "9bc1b62d-6ba0-4852-b621-ef96b2c4c1fc"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage9bc1b62d6ba04852B621Ef96b2c4c1fc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage9bc1b62d6ba04852B621Ef96b2c4c1fc gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header02c0d0b517284af79f91925ec2cc8e95" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterE8b90cf490e54529B74d93bfd50dd029" {...props} />}
    </div>
  );
}

// id: "5a25e5fd-6a61-4be4-9faf-166bc9c7602a"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage5a25e5fd6a614be49faf166bc9c7602a(props: any) {
  return (
    <LoginPage0722eb7a1f7d49bf8fa8C065ba9f2a9e parentTag="PasswordResetPage5a25e5fd6a614be49faf166bc9c7602a" {...props} />
  );
}

// id: "be25145e-4325-44c9-a817-ce7f9cd8bf77"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "0722eb7a-1f7d-49bf-8fa8-c065ba9f2a9e"
export function FooterBe25145e432544c9A817Ce7f9cd8bf77(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterBe25145e432544c9A817Ce7f9cd8bf77" {...props} />
  );
}

// id: "10b1c56a-c38d-434b-a9e7-27c2560e8a83"
// title: ""
// type: :text
// key: "alt"
// parent_id: "31efe6e7-cf95-4620-8db8-697f05631150"
export const Alt10b1c56aC38d434bA9e727c2560e8a83 = "logo";

// id: "31ca177c-66fc-44b5-85d5-82cd28a9d8fd"
// title: ""
// type: :text
// key: "title"
// parent_id: "ae834978-3e4c-4a88-ac54-93080d89caa3"
export const Title31ca177c66fc44b585d582cd28a9d8fd = "Resources";

// id: "9b881b4c-3b1c-4b1e-a2d2-6f38f03cd87e"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "7db43ffe-806d-4c5c-b291-7031b998e19e"
export const Title9b881b4c3b1c4b1eA2d26f38f03cd87e = "Login";

// id: "55b21936-607f-4a9e-8176-7e09718c8760"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader55b21936607f4a9e81767e09718c8760(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader55b21936607f4a9e81767e09718c8760 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoD391e3ef12e3448c8c96A50b069a5d91", parentTag)} label={<DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label77ffe9de7a6c42d0938f5bf767faf1e7" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationE4e7d374424840a398f8Fbd7cb92b386", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "f263f114-501d-4157-83b9-2bc648bacace"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "b14b2c2c-4872-4144-935f-27c99f672857"
export const NavMenuSlugF263f114501d415783b92bc648bacace = "marketing-primary-nav";

// id: "27ee9fce-3509-4ea4-b2f0-1d64e5b5f331"
// title: ""
// type: :text
// key: "title"
// parent_id: "b14b2c2c-4872-4144-935f-27c99f672857"
export const Title27ee9fce35094ea4B2f01d64e5b5f331 = null;

// id: "02a1213c-39e9-4748-83e6-d7f706a5e1c1"
// title: ""
// type: :html
// key: "logo"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function Logo02a1213c39e9474883e6D7f706a5e1c1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/QzpG2u-lwBzuCDcY_regular.png" alt="SP Seattle logo" className={`${parentTag || ""} Logo02a1213c39e9474883e6D7f706a5e1c1 logo`} />
  );
}

// id: "b6c39419-494e-4d2f-81df-7ccb3dacccb2"
// title: ""
// type: :text
// key: "title"
// parent_id: "8b6dfe5d-aacd-4780-8e49-251304f88d99"
export const TitleB6c39419494e4d2f81df7ccb3dacccb2 = "Secondary Marketing Title 2";

// id: "a1d54a87-11c3-4ffe-b39a-35d3164db602"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopB10289b2Ff8242c3B6e5969e53a65ba5" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero13705060C59b4611801988461c9a3b2e parentTag="Hero8b6dfe5dAacd47808e49251304f88d99" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "e8b90cf4-90e5-4529-b74d-93bfd50dd029"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9bc1b62d-6ba0-4852-b621-ef96b2c4c1fc"
export function FooterE8b90cf490e54529B74d93bfd50dd029(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterE8b90cf490e54529B74d93bfd50dd029" {...props} />
  );
}

// id: "821c2b23-606d-494b-b940-1eec51e9c788"
// title: ""
// type: :reference
// key: "header"
// parent_id: "98231ac0-42c6-464f-a643-76810dc25c19"
export function Header821c2b23606d494bB9401eec51e9c788(props: any) {
  return (
    <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="Header821c2b23606d494bB9401eec51e9c788" {...props} />
  );
}

// id: "3bee3f47-e857-4904-be24-960396fe14c7"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function Copyright3bee3f47E8574904Be24960396fe14c7(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright3bee3f47E8574904Be24960396fe14c7", parentTag)} {...props} />
  );
}

// id: "7db43ffe-806d-4c5c-b291-7031b998e19e"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "0722eb7a-1f7d-49bf-8fa8-c065ba9f2a9e"
export function Header7db43ffe806d4c5cB2917031b998e19e(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header7db43ffe806d4c5cB2917031b998e19e" title="Login" {...props} />
  );
}

// id: "3ca4c0a0-ef0f-426e-ac24-2d155ec0369e"
// title: ""
// type: :text
// key: "title"
// parent_id: "02c0d0b5-1728-4af7-9f91-925ec2cc8e95"
export const Title3ca4c0a0Ef0f426eAc242d155ec0369e = "Public Events";

// id: "06c0b2f2-7b63-4be5-9138-64d75744e627"
// title: ""
// type: :text
// key: "class"
// parent_id: "7294f303-066f-4205-be97-c225a0612464"
export const Class06c0b2f27b634be5913864d75744e627 = "navigation";

// id: "e4e7d374-4248-40a3-98f8-fbd7cb92b386"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "55b21936-607f-4a9e-8176-7e09718c8760"
export function NavigationE4e7d374424840a398f8Fbd7cb92b386(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationE4e7d374424840a398f8Fbd7cb92b386", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "71c89cf5-ed33-4138-bf17-4e6e4e1ed9fc"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "f1a94ddf-ab51-4156-886f-8d69c57feeb8"
export function Button71c89cf5Ed334138Bf174e6e4e1ed9fc(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button71c89cf5Ed334138Bf174e6e4e1ed9fc", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "e5a4aeaa-e409-4bd0-bdfe-ab7c52c1b57a"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "920afe7d-c23e-4d44-9444-6d3331a5f4bb"
export function FooterE5a4aeaaE4094bd0BdfeAb7c52c1b57a(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterE5a4aeaaE4094bd0BdfeAb7c52c1b57a" {...props} />
  );
}

// id: "b5316ab9-9b60-42f0-8bc5-f29ee30bc860"
// title: ""
// type: :text
// key: "title"
// parent_id: "13705060-c59b-4611-8019-88461c9a3b2e"
export const TitleB5316ab99b6042f08bc5F29ee30bc860 = "Default Title";

// id: "b10289b2-ff82-42c3-b6e5-969e53a65ba5"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "a1d54a87-11c3-4ffe-b39a-35d3164db602"
export function HeaderTopB10289b2Ff8242c3B6e5969e53a65ba5(props: any) {
  return (
    <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopB10289b2Ff8242c3B6e5969e53a65ba5" {...props} />
  );
}

// id: "92c550af-55e7-4262-9380-14df21c8f3e7"
// title: ""
// type: :text
// key: "title"
// parent_id: "b0e261a0-e5ce-4fc6-b888-6aab1a5314c2"
export const Title92c550af55e74262938014df21c8f3e7 = "Join Us";

// id: "b7d3a13e-3793-486a-835a-8f13cad91ec2"
// title: ""
// type: :text
// key: "href"
// parent_id: "71c89cf5-ed33-4138-bf17-4e6e4e1ed9fc"
export const HrefB7d3a13e3793486a835a8f13cad91ec2 = "/join";

// id: "f0a7547b-a0dd-4f69-af26-bab606ccf6f2"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "3f23ecc8-0e10-4059-935b-caa14d02e8d4"
export function HeaderTopF0a7547bA0dd4f69Af26Bab606ccf6f2(props: any) {
  return (
    <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopF0a7547bA0dd4f69Af26Bab606ccf6f2" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ceaac15b-fb7d-45ce-be92-139b8f038a64"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "55b21936-607f-4a9e-8176-7e09718c8760"
export const BackgroundImageCeaac15bFb7d45ceBe92139b8f038a64 = "https://mp1md-pub.s3.amazonaws.com/orgs/spsea/seattle-skyline-final.jpeg";

// id: "82e0f416-8bd9-46c8-8164-a9d68cf5e2d2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c3e07e23-0352-45d5-8e19-15c42dd2261e"
export function Footer82e0f4168bd946c88164A9d68cf5e2d2(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer82e0f4168bd946c88164A9d68cf5e2d2" {...props} />
  );
}

// id: "bfa0756a-c307-4909-9e17-1ed17e819933"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function LinksBfa0756aC30749099e171ed17e819933(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksBfa0756aC30749099e171ed17e819933", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "1db8d867-7139-45ce-b75a-7a79cbc19d5f"
// title: ""
// type: :reference
// key: "label"
// parent_id: "298ceebc-71d8-4dd6-a3ec-246dd30daa1b"
export function Label1db8d867713945ceB75a7a79cbc19d5f(props: any) {
  return (
    <DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label1db8d867713945ceB75a7a79cbc19d5f" {...props} />
  );
}

// id: "b270ce7a-7797-420c-8c00-cf16eb77b683"
// title: ""
// type: :text
// key: "title"
// parent_id: "63da9b4e-be0b-4e6a-965c-8e3cd43da58a"
export const TitleB270ce7a7797420c8c00Cf16eb77b683 = "Secondary Page";

// id: "f1a94ddf-ab51-4156-886f-8d69c57feeb8"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroF1a94ddfAb514156886f8d69c57feeb8(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroF1a94ddfAb514156886f8d69c57feeb8 hero`}>
      <div className="title">
        Sex Positive Seattle
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button71c89cf5Ed334138Bf174e6e4e1ed9fc", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "63da9b4e-be0b-4e6a-965c-8e3cd43da58a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c3e07e23-0352-45d5-8e19-15c42dd2261e"
export function Header63da9b4eBe0b4e6a965c8e3cd43da58a(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header63da9b4eBe0b4e6a965c8e3cd43da58a" title="Secondary Page" {...props} />
  );
}

// id: "02c0d0b5-1728-4af7-9f91-925ec2cc8e95"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9bc1b62d-6ba0-4852-b621-ef96b2c4c1fc"
export function Header02c0d0b517284af79f91925ec2cc8e95(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header02c0d0b517284af79f91925ec2cc8e95" title="Public Events" {...props} />
  );
}

// id: "fb37d177-23ed-4126-8dbb-77480c241399"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "6ccf55d9-60d8-49f2-9ba2-f1da3bfae29d"
export function HeaderTopFb37d17723ed41268dbb77480c241399(props: any) {
  return (
    <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopFb37d17723ed41268dbb77480c241399" {...props} />
  );
}

// id: "c5413a80-b9b0-45eb-9ccc-3ba9976dc17e"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "e4e7d374-4248-40a3-98f8-fbd7cb92b386"
export const NavMenuSlugC5413a80B9b045eb9ccc3ba9976dc17e = "members-primary-nav";

// id: "98231ac0-42c6-464f-a643-76810dc25c19"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages98231ac042c6464fA64376810dc25c19(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages98231ac042c6464fA64376810dc25c19 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="Header821c2b23606d494bB9401eec51e9c788" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav19e547e0Dcc34cd69396027138fc29a1", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer138be23069594e788c7aD2b90bafecf2" {...props} />}
    </div>
  );
}

// id: "298ceebc-71d8-4dd6-a3ec-246dd30daa1b"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "51825e22-4cf8-4343-8afd-166ba0ffe905"
export function LinkedLogo298ceebc71d84dd6A3ec246dd30daa1b(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo298ceebc71d84dd6A3ec246dd30daa1b", parentTag)} label={<DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label1db8d867713945ceB75a7a79cbc19d5f" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "ae834978-3e4c-4a88-ac54-93080d89caa3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4f1cd43d-d1fb-4a10-959b-1dd9bf449394"
export function HeaderAe8349783e4c4a88Ac5493080d89caa3(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderAe8349783e4c4a88Ac5493080d89caa3" title="Resources" {...props} />
  );
}

// id: "8b6dfe5d-aacd-4780-8e49-251304f88d99"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "a1d54a87-11c3-4ffe-b39a-35d3164db602"
export function Hero8b6dfe5dAacd47808e49251304f88d99(props: any) {
  return (
    <SecondaryMarketingHero13705060C59b4611801988461c9a3b2e parentTag="Hero8b6dfe5dAacd47808e49251304f88d99" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "d391e3ef-12e3-448c-8c96-a50b069a5d91"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "55b21936-607f-4a9e-8176-7e09718c8760"
export function LinkedLogoD391e3ef12e3448c8c96A50b069a5d91(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoD391e3ef12e3448c8c96A50b069a5d91", parentTag)} label={<DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label77ffe9de7a6c42d0938f5bf767faf1e7" alt="logo" />} {...props} />
  );
}

// id: "77ffe9de-7a6c-42d0-938f-5bf767faf1e7"
// title: ""
// type: :reference
// key: "label"
// parent_id: "d391e3ef-12e3-448c-8c96-a50b069a5d91"
export function Label77ffe9de7a6c42d0938f5bf767faf1e7(props: any) {
  return (
    <DefaultLogo31efe6e7Cf9546208db8697f05631150 parentTag="Label77ffe9de7a6c42d0938f5bf767faf1e7" {...props} />
  );
}

// id: "2744754f-b26f-4c81-a683-49031ffb2347"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "bfa0756a-c307-4909-9e17-1ed17e819933"
export const NavMenuSlug2744754fB26f4c81A68349031ffb2347 = "footer-nav";

// id: "838d4f9d-4d05-4fc0-9d11-fe830f9ad464"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages838d4f9d4d054fc09d11Fe830f9ad464(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages838d4f9d4d054fc09d11Fe830f9ad464 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="HeaderF6652eab639a4e2b91fcEf8f98f06b49" {...props} />}
      {props.children}
    </div>
  );
}

// id: "275d132a-08a0-47ac-90c2-32e2c08f21c0"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function SocialLinks275d132a08a047ac90c232e2c08f21c0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks275d132a08a047ac90c232e2c08f21c0 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "19e547e0-dcc3-4cd6-9396-027138fc29a1"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "98231ac0-42c6-464f-a643-76810dc25c19"
export function SecondaryNav19e547e0Dcc34cd69396027138fc29a1(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav19e547e0Dcc34cd69396027138fc29a1", parentTag)} {...props} />
  );
}

// id: "548522ec-2e0a-4fd7-af89-258fb44a5146"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "8a31c08b-35f4-4c5c-8829-fd50642deafd"
export const ButtonClass548522ec2e0a4fd7Af89258fb44a5146 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "daf14fe1-c703-4725-b78f-dff0098a345b"
// title: ""
// type: :text
// key: "title"
// parent_id: "a1d54a87-11c3-4ffe-b39a-35d3164db602"
export const TitleDaf14fe1C7034725B78fDff0098a345b = "Secondary Marketing Title 1";

// id: "38ee9d30-63a9-4a4e-b2f9-77da6cc4cf81"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "cd7dda6c-3cf8-4301-b872-89c996f2ea14"
export const NavMenuSlug38ee9d3063a94a4eB2f977da6cc4cf81 = "members-primary-nav";

// id: "c495a2b0-d20f-421b-94d6-e64f852fa914"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "f0a7547b-a0dd-4f69-af26-bab606ccf6f2"
export const NavMenuSlugC495a2b0D20f421b94d6E64f852fa914 = "members-primary-nav";

// id: "48945973-b27c-42c8-8384-884c29a61c40"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function SocialLinks48945973B27c42c88384884c29a61c40(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks48945973B27c42c88384884c29a61c40 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sp_seattle/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/spseattle">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "f6652eab-639a-4e2b-91fc-ef8f98f06b49"
// title: ""
// type: :reference
// key: "header"
// parent_id: "838d4f9d-4d05-4fc0-9d11-fe830f9ad464"
export function HeaderF6652eab639a4e2b91fcEf8f98f06b49(props: any) {
  return (
    <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="HeaderF6652eab639a4e2b91fcEf8f98f06b49" {...props} />
  );
}

// id: "31d7d9d3-a4c2-4ed7-8536-650f82da5fbb"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "c2a6801c-1960-44a0-8003-66447a0aac44"
export function Groupflow31d7d9d3A4c24ed78536650f82da5fbb(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow31d7d9d3A4c24ed78536650f82da5fbb", parentTag)} {...props} />
  );
}

// id: "cd7dda6c-3cf8-4301-b872-89c996f2ea14"
// title: ""
// type: :reference
// key: "header"
// parent_id: "920afe7d-c23e-4d44-9444-6d3331a5f4bb"
export function HeaderCd7dda6c3cf84301B87289c996f2ea14(props: any) {
  return (
    <MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4 parentTag="HeaderCd7dda6c3cf84301B87289c996f2ea14" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "5a86cf4d-d45f-42c9-9a91-06f776758213"
// title: ""
// type: :text
// key: "label"
// parent_id: "71c89cf5-ed33-4138-bf17-4e6e4e1ed9fc"
export const Label5a86cf4dD45f42c99a9106f776758213 = "Join Us";

// id: "5b845164-128e-416c-b7ba-ca06ac9d3d64"
// title: ""
// type: :text
// key: "class"
// parent_id: "298ceebc-71d8-4dd6-a3ec-246dd30daa1b"
export const Class5b845164128e416cB7baCa06ac9d3d64 = "linked-logo";

// id: "e097e70c-5bb9-49d4-aa1c-db9238242662"
// title: ""
// type: :text
// key: "title"
// parent_id: "4eb4a2f3-e870-4abb-aa6e-1acde6c6e1a3"
export const TitleE097e70c5bb949d4Aa1cDb9238242662 = "Join Us";

// id: "fa094d8e-9741-4ea9-a91c-265b5b8968c4"
// title: "Widget ID"
// type: :text
// key: "widgetId"
// parent_id: "3f23ecc8-0e10-4059-935b-caa14d02e8d4"
export const WidgetIdFa094d8e97414ea9A91c265b5b8968c4 = "c0204c14-06ef-4cf2-8e16-8b9bbc1ba21e";

// id: "b0e261a0-e5ce-4fc6-b888-6aab1a5314c2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9e5ae215-c543-4912-b6a9-f13f832e8a52"
export function HeaderB0e261a0E5ce4fc6B8886aab1a5314c2(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderB0e261a0E5ce4fc6B8886aab1a5314c2" title="Join Us" {...props} />
  );
}

// id: "7294f303-066f-4205-be97-c225a0612464"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "51825e22-4cf8-4343-8afd-166ba0ffe905"
export function Navigation7294f303066f4205Be97C225a0612464(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation7294f303066f4205Be97C225a0612464", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "4eb4a2f3-e870-4abb-aa6e-1acde6c6e1a3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e200c486-0562-43ae-b481-70c4408d2874"
export function Header4eb4a2f3E8704abbAa6e1acde6c6e1a3(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header4eb4a2f3E8704abbAa6e1acde6c6e1a3" title="Join Us" {...props} />
  );
}

// id: "138be230-6959-4e78-8c7a-d2b90bafecf2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "98231ac0-42c6-464f-a643-76810dc25c19"
export function Footer138be23069594e788c7aD2b90bafecf2(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer138be23069594e788c7aD2b90bafecf2" {...props} />
  );
}

// id: "2497b1bf-22f8-434a-b62b-2eed8c031f73"
// title: ""
// type: :text
// key: "url"
// parent_id: "298ceebc-71d8-4dd6-a3ec-246dd30daa1b"
export const Url2497b1bf22f8434aB62b2eed8c031f73 = "/";

// id: "749eca1e-183a-498d-b7ff-5af6c831f3dd"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "7294f303-066f-4205-be97-c225a0612464"
export const NavMenuSlug749eca1e183a498dB7ff5af6c831f3dd = "marketing-primary-nav";

// id: "31efe6e7-cf95-4620-8db8-697f05631150"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo31efe6e7Cf9546208db8697f05631150(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/QzpG2u-lwBzuCDcY_regular.png" className={`${parentTag || ""} DefaultLogo31efe6e7Cf9546208db8697f05631150 logo`} alt="logo" />
  );
}

// id: "6ccf55d9-60d8-49f2-9ba2-f1da3bfae29d"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader6ccf55d960d849f29ba2F1da3bfae29d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader6ccf55d960d849f29ba2F1da3bfae29d site-header`}>
      {props["header-top"] || <DefaultHeaderTop51825e224cf843438afd166ba0ffe905 parentTag="HeaderTopFb37d17723ed41268dbb77480c241399" {...props} />}
      {props["hero"] || <Hero7c64c36eD94841f99c1355c14a5b553f />}
    </div>
  );
}

// id: "9d3d4d0c-8ec5-4a8f-a28b-2745926919bd"
// title: "About Us 2"
// type: :html
// key: "about2"
// parent_id: nil
export function About29d3d4d0c8ec54a8fA28b2745926919bd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>

    </>
  );
}

// id: "8928a42a-866f-4f4b-9a19-866be6ebb85b"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage8928a42a866f4f4b9a19866be6ebb85b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage8928a42a866f4f4b9a19866be6ebb85b page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderB597904e03d946658b154f84b0a28e6c" title="About Us" {...props} />}
      {props["body"] || <Body7df8a016309e493e86ff3656f24406d5 />}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterD8526396C44941bf92948b126ee10764" {...props} />}
    </div>
  );
}

// id: "b597904e-03d9-4665-8b15-4f84b0a28e6c"
// title: "Header"
// type: :reference
// key: "header"
// parent_id: "8928a42a-866f-4f4b-9a19-866be6ebb85b"
export function HeaderB597904e03d946658b154f84b0a28e6c(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderB597904e03d946658b154f84b0a28e6c" title="About Us" {...props} />
  );
}

// id: "7df8a016-309e-493e-86ff-3656f24406d5"
// title: "About Body"
// type: :html
// key: "body"
// parent_id: "8928a42a-866f-4f4b-9a19-866be6ebb85b"
export function Body7df8a016309e493e86ff3656f24406d5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Body7df8a016309e493e86ff3656f24406d5`}>
      {props["about-marketing-cards"] || <MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef parentTag="AboutMarketingCards75cd850e75c040eeA6f80490d57437f8" postSlug="about-us-posts" {...props} />}
    </main>
  );
}

// id: "d8526396-c449-41bf-9294-8b126ee10764"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "8928a42a-866f-4f4b-9a19-866be6ebb85b"
export function FooterD8526396C44941bf92948b126ee10764(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterD8526396C44941bf92948b126ee10764" {...props} />
  );
}

// id: "f4758d51-eed0-462a-bb28-af090f27f7ce"
// title: ""
// type: :text
// key: "title"
// parent_id: "b597904e-03d9-4665-8b15-4f84b0a28e6c"
export const TitleF4758d51Eed0462aBb28Af090f27f7ce = "About Us";

// id: "13705060-c59b-4611-8019-88461c9a3b2e"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero13705060C59b4611801988461c9a3b2e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero13705060C59b4611801988461c9a3b2e hero`}>
      <div className="background-opacity" />
      <h1 className="title">
        {props["title"] || TitleB5316ab99b6042f08bc5F29ee30bc860}
      </h1>
    </div>
  );
}

// id: "d57bd2f7-6a08-43cb-872f-1c4155897f22"
// title: "Marketing Cards 2"
// type: :html
// key: "marketing-cards2"
// parent_id: "e226638c-a067-48b6-9718-67905336a3a6"
export function MarketingCards2D57bd2f76a0843cb872f1c4155897f22(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <section className={`${parentTag || ""} MarketingCards2D57bd2f76a0843cb872f1c4155897f22`}>
      <div className="marketing-card-main-container">
        <div className="who-we-are-element">
          <div className="inner-border-container-element">
            <h2 className="who-we-are-heading-element">
              Who We Are
            </h2>
            <p className="who-we-are-paragraph-element">
              SP Seattle is a non-profit organization in Seattle,
              Washington that creates educational and
              social
              experiences around positive sexuality, identity, lifestyle, consent, and body-positivity for
              adults.
            </p>
            <p className="who-we-are-paragraph-element">
              We launched in March 2024, and we will be ready for new
              member applications soon!
            </p>
          </div>
        </div>
        <div className="who-we-are-image-element">
          <img className="image-element" src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/AZySzGurDf4eCeEg_regular.jpeg" alt="who we are" />
        </div>
        <div className="sex-positivity-image-element">
          <img className="image-element" src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/lHfLCVECw-9i4im6_regular.jpeg" alt="who we are" />
        </div>
        <div className="what-is-sex-positivity-element">
          <div className="inner-border-container-element">
            <h2 className="what-is-sex-positivity-heading-element">
              What is Sex Positivity?
            </h2>
            <p className="what-is-sex-positivity-paragraph-element">
              Sex positivity is a social movement, and
              philosophy which embodies creating a world where
              sexuality
              is
              seen as natural, healthy, and essential part of the human experience, where pleasure is
              recognized
              as
              good, and where sexual orientations and relationships styles are accepted and supported.
            </p>
            <p className="what-is-sex-positivity-paragraph-element">
              Sex positivity also places a high emphasis on
              informed consent, and body sovereignty. We advocate
              for
              sex
              education, and risk aware sex.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

// id: "bdc30c13-5426-4a28-9adc-3f7e3476232b"
// title: "Header Clone Rircc6FmPMjLF30"
// type: :reference
// key: "header"
// parent_id: "3ee6b6ff-6bb5-4ee0-8288-df3c39e9b4ec"
export function HeaderBdc30c1354264a289adc3f7e3476232b(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderBdc30c1354264a289adc3f7e3476232b" title="Event Levels Explained" {...props} />
  );
}

// id: "0ce3c85a-bbba-40a8-9db5-dc4848a9c782"
// title: ""
// type: :gf_preset
// key: "body"
// parent_id: "3ee6b6ff-6bb5-4ee0-8288-df3c39e9b4ec"
export function Body0ce3c85aBbba40a89db5Dc4848a9c782(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageContent parentTag={buildClassName("Body0ce3c85aBbba40a89db5Dc4848a9c782", parentTag)} {...props} />
  );
}

// id: "f7cac1bb-f990-4ffa-b5f7-0a89f0cbcd0b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "3ee6b6ff-6bb5-4ee0-8288-df3c39e9b4ec"
export function FooterF7cac1bbF9904ffaB5f70a89f0cbcd0b(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterF7cac1bbF9904ffaB5f70a89f0cbcd0b" {...props} />
  );
}

// id: "3ee6b6ff-6bb5-4ee0-8288-df3c39e9b4ec"
// title: "Leveling System"
// type: :html
// key: "leveling-page"
// parent_id: nil
export function LevelingPage3ee6b6ff6bb54ee08288Df3c39e9b4ec(props: any) {
  const PageContent = AppStore.presets["PageContent"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} LevelingPage3ee6b6ff6bb54ee08288Df3c39e9b4ec page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="HeaderBdc30c1354264a289adc3f7e3476232b" title="Event Levels Explained" {...props} />}
      {props["body"] || <PageContent parentTag={buildClassName("Body0ce3c85aBbba40a89db5Dc4848a9c782", parentTag)} {...props} />}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterF7cac1bbF9904ffaB5f70a89f0cbcd0b" {...props} />}
    </div>
  );
}

// id: "2f7b611a-59cc-45dd-8bb8-a8ab500592ab"
// title: ""
// type: :text
// key: "title"
// parent_id: "bdc30c13-5426-4a28-9adc-3f7e3476232b"
export const Title2f7b611a59cc45dd8bb8A8ab500592ab = "Event Levels Explained";

// id: "665b3fd3-e25d-4045-8a4d-91d08aeab078"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "8d58f654-b2fd-4e0e-b552-e02fc7fc8eef"
export const PostSlug665b3fd3E25d40458a4d91d08aeab078 = "how-were-different-marketing-cards";

// id: "6d7c6bf6-bdc9-4631-b4a4-bd8c79b9c1a7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ea86b6bd-46e7-44d8-a12c-74d046f97c17"
export function Header6d7c6bf6Bdc94631B4a4Bd8c79b9c1a7(props: any) {
  return (
    <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header6d7c6bf6Bdc94631B4a4Bd8c79b9c1a7" title="How We&#39;re Different" {...props} />
  );
}

// id: "9002c40c-6cea-4d05-b3a8-70bf772ac38c"
// title: ""
// type: :html
// key: "body"
// parent_id: "ea86b6bd-46e7-44d8-a12c-74d046f97c17"
export function Body9002c40c6cea4d05B3a870bf772ac38c(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} Body9002c40c6cea4d05B3a870bf772ac38c`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef", parentTag)} postSlug="how-were-different-marketing-cards" {...props} />}
    </main>
  );
}

// id: "2358370a-520d-43fb-a182-4b6aabac769c"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ea86b6bd-46e7-44d8-a12c-74d046f97c17"
export function Footer2358370a520d43fbA1824b6aabac769c(props: any) {
  return (
    <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer2358370a520d43fbA1824b6aabac769c" {...props} />
  );
}

// id: "ea86b6bd-46e7-44d8-a12c-74d046f97c17"
// title: "How We're Different"
// type: :html
// key: "how-were-different"
// parent_id: nil
export function HowWereDifferentEa86b6bd46e744d8A12c74d046f97c17(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HowWereDifferentEa86b6bd46e744d8A12c74d046f97c17 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602 parentTag="Header6d7c6bf6Bdc94631B4a4Bd8c79b9c1a7" title="How We&#39;re Different" {...props} />}
      {props["body"] || <Body9002c40c6cea4d05B3a870bf772ac38c />}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="Footer2358370a520d43fbA1824b6aabac769c" {...props} />}
    </div>
  );
}

// id: "db725a86-b2ae-460b-b222-f6ce2b7cedef"
// title: ""
// type: :text
// key: "title"
// parent_id: "6d7c6bf6-bdc9-4631-b4a4-bd8c79b9c1a7"
export const TitleDb725a86B2ae460bB222F6ce2b7cedef = "How We're Different";

// id: "8d58f654-b2fd-4e0e-b552-e02fc7fc8eef"
// title: ""
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "9002c40c-6cea-4d05-b3a8-70bf772ac38c"
export function MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef", parentTag)} postSlug="how-were-different-marketing-cards" {...props} />
  );
}

// id: "ae61bb66-76ff-4bc0-b32c-c5616fc39386"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageAe61bb6676ff4bc0B32cC5616fc39386(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageAe61bb6676ff4bc0B32cC5616fc39386 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader6ccf55d960d849f29ba2F1da3bfae29d parentTag="Header70dabe96C1124895B3283011e38c4168" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack16fa61409f3c4fef951cAb8d597b790f", parentTag)} {...props} />}
      {props["body"] || <BodyD898715eCf334c129333A0a35b847533 />}
      {props["footer"] || <DefaultFooterC2a6801c196044a0800366447a0aac44 parentTag="FooterF760c2dd8d5a43bf93b32cafb682fb7d" {...props} />}
    </div>
  );
}

// id: "c2a6801c-1960-44a0-8003-66447a0aac44"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterC2a6801c196044a0800366447a0aac44(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const ContentSnippet = AppStore.presets["ContentSnippet"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterC2a6801c196044a0800366447a0aac44 footer`}>
      {props["social-links"] || <SocialLinks48945973B27c42c88384884c29a61c40 />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksBfa0756aC30749099e171ed17e819933", parentTag)} navMenuSlug="footer-nav" {...props} />}
      {props["donate-button"] || <ContentSnippet parentTag={buildClassName("DonateButtonBbc375236cfb45a4B6f509d4d39c2293", parentTag)} contentSlug="footer-donate-button" className="donate-button" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo02a1213c39e9474883e6D7f706a5e1c1 />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright3bee3f47E8574904Be24960396fe14c7", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow31d7d9d3A4c24ed78536650f82da5fbb", parentTag)} {...props} />}
    </div>
  );
}

const Components = {
  MembersAreaHeader3f23ecc80e104059935bCaa14d02e8d4,
  NavigationPurposeEab9b49dCf2445ed9fc10a123955add4,
  Header70dabe96C1124895B3283011e38c4168,
  BodyD898715eCf334c129333A0a35b847533,
  HomeMarketingCards7eca47775fbf4e6f9a75E6a40ac9ab52,
  MarketingCards8a31c08b35f44c5c8829Fd50642deafd,
  FooterF760c2dd8d5a43bf93b32cafb682fb7d,
  WelcomeBack16fa61409f3c4fef951cAb8d597b790f,
  DefaultHeaderTop51825e224cf843438afd166ba0ffe905,
  PostSlug87adb06764d749368436E220dc9446f3,
  ContentSlugA7f50569B52f4b24B40448438cda031c,
  ClassName03da905504824f258b4b71074a8a3226,
  PostSlugB6831adc63ec4c13A3ff678eb08477ed,
  AboutMarketingCards75cd850e75c040eeA6f80490d57437f8,
  WidgetId9edd68b88dca4252828b48444491155d,
  Title557999cbA0d04b1eB5488ef11303e699,
  BodyF6fd381fBc2f4529A2564199aa23d3a1,
  ClassName77d7a9c151c64c85Bc962f83e19a8532,
  ContentSlugBd44a16641db41f192076e5d0349872a,
  Button9382b9d9214148ceBd53F6738ef114ec,
  ContentSlugE12a7f1fBe354ff783b6401fa003f5e9,
  ClassNameC6743492E9a04a12919fE12d5d4885ba,
  Hero7c64c36eD94841f99c1355c14a5b553f,
  DonateButtonBbc375236cfb45a4B6f509d4d39c2293,
  ContentSlug2d30736938e441b0951853950e96e678,
  ClassNameC6b3d02b78664438Bd42F609593e225c,
  AdminPages941ebc6897e049a5893a7043db151e4a,
  Label63d85fb5E62c4cd4B0a3F641aa47bda1,
  MembersAreaDefault920afe7dC23e4d4494446d3331a5f4bb,
  ResourcesPage4f1cd43dD1fb4a10959b1dd9bf449394,
  DefaultHeaderB14b2c2c48724144935f27c99f672857,
  SecondaryPageC3e07e23035245d58e1915c42dd2261e,
  PostSlug2689e5b7Af3e48b5Ae568f47897c6077,
  JoinUsPage9e5ae215C5434912B6a9F13f832e8a52,
  Href97c6b8a428c54ace847c106847e4f4db,
  LoginPage0722eb7a1f7d49bf8fa8C065ba9f2a9e,
  MarketingBodyE226638cA06748b6971867905336a3a6,
  PageQuote647c7dff74474c349620876fd9363ec4,
  Footer1e0365f731be48b9A7afD8cd1dd2217d,
  SignupPagesE200c486056243aeB48170c4408d2874,
  PublicEventsPage9bc1b62d6ba04852B621Ef96b2c4c1fc,
  PasswordResetPage5a25e5fd6a614be49faf166bc9c7602a,
  FooterBe25145e432544c9A817Ce7f9cd8bf77,
  Alt10b1c56aC38d434bA9e727c2560e8a83,
  Title31ca177c66fc44b585d582cd28a9d8fd,
  Title9b881b4c3b1c4b1eA2d26f38f03cd87e,
  ShortHeader55b21936607f4a9e81767e09718c8760,
  NavMenuSlugF263f114501d415783b92bc648bacace,
  Title27ee9fce35094ea4B2f01d64e5b5f331,
  Logo02a1213c39e9474883e6D7f706a5e1c1,
  TitleB6c39419494e4d2f81df7ccb3dacccb2,
  SecondaryMarketingHeaderA1d54a8711c34ffeB39a35d3164db602,
  FooterE8b90cf490e54529B74d93bfd50dd029,
  Header821c2b23606d494bB9401eec51e9c788,
  Copyright3bee3f47E8574904Be24960396fe14c7,
  Header7db43ffe806d4c5cB2917031b998e19e,
  Title3ca4c0a0Ef0f426eAc242d155ec0369e,
  Class06c0b2f27b634be5913864d75744e627,
  NavigationE4e7d374424840a398f8Fbd7cb92b386,
  Button71c89cf5Ed334138Bf174e6e4e1ed9fc,
  FooterE5a4aeaaE4094bd0BdfeAb7c52c1b57a,
  TitleB5316ab99b6042f08bc5F29ee30bc860,
  HeaderTopB10289b2Ff8242c3B6e5969e53a65ba5,
  Title92c550af55e74262938014df21c8f3e7,
  HrefB7d3a13e3793486a835a8f13cad91ec2,
  HeaderTopF0a7547bA0dd4f69Af26Bab606ccf6f2,
  BackgroundImageCeaac15bFb7d45ceBe92139b8f038a64,
  Footer82e0f4168bd946c88164A9d68cf5e2d2,
  LinksBfa0756aC30749099e171ed17e819933,
  Label1db8d867713945ceB75a7a79cbc19d5f,
  TitleB270ce7a7797420c8c00Cf16eb77b683,
  HomePageHeroF1a94ddfAb514156886f8d69c57feeb8,
  Header63da9b4eBe0b4e6a965c8e3cd43da58a,
  Header02c0d0b517284af79f91925ec2cc8e95,
  HeaderTopFb37d17723ed41268dbb77480c241399,
  NavMenuSlugC5413a80B9b045eb9ccc3ba9976dc17e,
  MemberPages98231ac042c6464fA64376810dc25c19,
  LinkedLogo298ceebc71d84dd6A3ec246dd30daa1b,
  HeaderAe8349783e4c4a88Ac5493080d89caa3,
  Hero8b6dfe5dAacd47808e49251304f88d99,
  LinkedLogoD391e3ef12e3448c8c96A50b069a5d91,
  Label77ffe9de7a6c42d0938f5bf767faf1e7,
  NavMenuSlug2744754fB26f4c81A68349031ffb2347,
  MessagePages838d4f9d4d054fc09d11Fe830f9ad464,
  SocialLinks275d132a08a047ac90c232e2c08f21c0,
  SecondaryNav19e547e0Dcc34cd69396027138fc29a1,
  ButtonClass548522ec2e0a4fd7Af89258fb44a5146,
  TitleDaf14fe1C7034725B78fDff0098a345b,
  NavMenuSlug38ee9d3063a94a4eB2f977da6cc4cf81,
  NavMenuSlugC495a2b0D20f421b94d6E64f852fa914,
  SocialLinks48945973B27c42c88384884c29a61c40,
  HeaderF6652eab639a4e2b91fcEf8f98f06b49,
  Groupflow31d7d9d3A4c24ed78536650f82da5fbb,
  HeaderCd7dda6c3cf84301B87289c996f2ea14,
  Label5a86cf4dD45f42c99a9106f776758213,
  Class5b845164128e416cB7baCa06ac9d3d64,
  TitleE097e70c5bb949d4Aa1cDb9238242662,
  WidgetIdFa094d8e97414ea9A91c265b5b8968c4,
  HeaderB0e261a0E5ce4fc6B8886aab1a5314c2,
  Navigation7294f303066f4205Be97C225a0612464,
  Header4eb4a2f3E8704abbAa6e1acde6c6e1a3,
  Footer138be23069594e788c7aD2b90bafecf2,
  Url2497b1bf22f8434aB62b2eed8c031f73,
  NavMenuSlug749eca1e183a498dB7ff5af6c831f3dd,
  DefaultLogo31efe6e7Cf9546208db8697f05631150,
  HomeHeader6ccf55d960d849f29ba2F1da3bfae29d,
  About29d3d4d0c8ec54a8fA28b2745926919bd,
  AboutUsPage8928a42a866f4f4b9a19866be6ebb85b,
  HeaderB597904e03d946658b154f84b0a28e6c,
  Body7df8a016309e493e86ff3656f24406d5,
  FooterD8526396C44941bf92948b126ee10764,
  TitleF4758d51Eed0462aBb28Af090f27f7ce,
  SecondaryMarketingHero13705060C59b4611801988461c9a3b2e,
  MarketingCards2D57bd2f76a0843cb872f1c4155897f22,
  HeaderBdc30c1354264a289adc3f7e3476232b,
  Body0ce3c85aBbba40a89db5Dc4848a9c782,
  FooterF7cac1bbF9904ffaB5f70a89f0cbcd0b,
  LevelingPage3ee6b6ff6bb54ee08288Df3c39e9b4ec,
  Title2f7b611a59cc45dd8bb8A8ab500592ab,
  PostSlug665b3fd3E25d40458a4d91d08aeab078,
  Header6d7c6bf6Bdc94631B4a4Bd8c79b9c1a7,
  Body9002c40c6cea4d05B3a870bf772ac38c,
  Footer2358370a520d43fbA1824b6aabac769c,
  HowWereDifferentEa86b6bd46e744d8A12c74d046f97c17,
  TitleDb725a86B2ae460bB222F6ce2b7cedef,
  MarketingCards8d58f654B2fd4e0eB552E02fc7fc8eef,
  HomePageAe61bb6676ff4bc0B32cC5616fc39386,
  DefaultFooterC2a6801c196044a0800366447a0aac44
}

export default Components;