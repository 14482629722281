const ROUTES = {
  "/": {
    "name": "HomePageAe61bb6676ff4bc0B32cC5616fc39386",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPages941ebc6897e049a5893a7043db151e4a",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage8928a42a866f4f4b9a19866be6ebb85b",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPage9bc1b62d6ba04852B621Ef96b2c4c1fc",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage9e5ae215C5434912B6a9F13f832e8a52",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage0722eb7a1f7d49bf8fa8C065ba9f2a9e",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPages98231ac042c6464fA64376810dc25c19",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages838d4f9d4d054fc09d11Fe830f9ad464",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage5a25e5fd6a614be49faf166bc9c7602a",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPage4f1cd43dD1fb4a10959b1dd9bf449394",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/resources/how-were-different": {
    "name": "HowWereDifferentEa86b6bd46e744d8A12c74d046f97c17",
    "type": "HTML",
    "key": "how-were-different"
  },
  "/resources/leveling": {
    "name": "LevelingPage3ee6b6ff6bb54ee08288Df3c39e9b4ec",
    "type": "HTML",
    "key": "leveling-page"
  },
  "/signup/*": {
    "name": "SignupPagesE200c486056243aeB48170c4408d2874",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;